<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="안전관리자" 
            name="chmDangerManageUserId" 
            v-model="searchParam.chmDangerManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="LBLMANAGER" 
            name="chmDangerUserId" 
            v-model="searchParam.chmDangerUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            type="user" 
            label="안전대리자" 
            name="chmDangerSafetyUserId" 
            v-model="searchParam.chmDangerSafetyUserId" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="위험물 저장소 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :selection="popupParam.type"
      rowKey="chmDangerMstId"
      gridHeight="500px"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "danger-master-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          regularCheck: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerTypeName",
            field: "chmDangerTypeName",
            label: "구분",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerArea",
            field: "chmDangerArea",
            label: "설치장소",
            style: 'width:200px',
            align: "left",
            type: "link",
            sortable: true,
          },
          {
            name: "chmDangerMstName",
            field: "chmDangerMstName",
            label: "품명 표기",
            style: 'width:300px',
            align: "left",
            sortable: true,
          },
          {
            name: "chmDangerUserName",
            field: "chmDangerUserName",
            style: 'width:200px',
            label: "LBLMANAGER",
            align: "center",
            sortable: true,
          },
          {
            name: "chmDangerCount",
            field: "chmDangerCount",
            style: 'width:250px',
            label: "지정수량 표기",
            align: "left",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        chmDangerManageUserId: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        regularCheck: false,
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.master.list.url;
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      } 
      if (this.popupParam.regularCheck) {
        this.searchParam.regularCheck = this.popupParam.regularCheck;
      } 
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "위험물저장소 상세"; // 위험물저장소 상세
      this.popupOptions.param = {
        chmDangerMstId: row ? row.chmDangerMstId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./dangerMasterDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
